import React from 'react';
import './App.css';
import Header from './Header';
import Home from './Home';
import Footer from './Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  return (
    <div className="content">
    <Header />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/contact" element={<Contact />}  */}
        {/* Do not define a route for /blog here */}
      </Routes>
    </Router>
    <Footer />
    </div>
  );
}


export default App;
